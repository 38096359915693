.customTd,
.customTh {
  border: 1px solid #161717 !important;
  color: #0a0c0d !important;
  padding: 1px !important;
  margin: 1px !important;
}
.divider {
  color: #0a0c0d !important;
}
