.customTable {
  border-collapse: collapse;
}
.customTd,
.customTh {
  border: 1px solid black;
  padding: 4px 8px;
  text-align: center;
  font-size: medium;
}
.customTdg,
.customThg {
  border: 1px solid black;
  padding: 2px 4px;
  text-align: center;
  font-size: smaller;
}
.ndPart {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding-left: 12px;
  margin-bottom: 1px;
}

.modal-body-custom {
  margin: 0 auto !important;
  width: 95%;
  margin-top: 20px;
  padding: 6px !important;
}

.part1 {
  border: 1px solid black;
  border-bottom: none;
}

.std_info_grading {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
}

.std_info_grading .grading {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.std_info > div {
  color: black;
  padding-bottom: 2px;
  display: flex;
}
.std_info > div > div:first-of-type {
  width: 300px;
}

.table2 tr > th:first-of-type,
.table2 tr > td:first-of-type {
  border-left: none;
}
